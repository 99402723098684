/** @ngInject */

function AIService ($rootScope, $q, $window) {
  class Completion {
    constructor (messages, options = {}, callback) {
      this.messages = messages
      this.callback = callback
      if (typeof options === 'function') {
        this.callback = options
        options = {}
      }
      this.AbortController = null
      this.message = ''
      this.options = options
    }

    send (content = '') {
      if (content) {
        this.messages.push({ role: 'user', content })
      }

      this.AbortController = new AbortController()
      const signal = this.AbortController.signal

      const payload = {
        messages: this.messages,
        options: {
          max_tokens: this.options.max_tokens,
          temperature: this.options.temperature
        }
      }

      return $q((resolve, reject) => {
        fetch('/api/AI/completion', {
          method: 'POST',
          body: JSON.stringify(payload),
          signal,
          headers: {
            'Content-Type': 'application/json',
            Authorization: $rootScope.currentUser.accessTokenId
          }
        })
          .then(response => {
            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`)
            }
            return this.processStream(response)
          })
          .then(() => {
            this.messages.push({ role: 'assistant', content: this.message })
            console.log('Stream complete:', this.message)
            resolve(this.message)
          })
          .catch(error => {
            if (error.name === 'AbortError') {
              resolve(this.message)
            } else {
              console.error('Fetch error:', error)
              reject(error)
            }
          })
      })
    }

    async processStream (response) {
      const reader = response.body.getReader()
      const decoder = new TextDecoder()

      try {
        while (true) {
          const { done, value } = await reader.read()
          if (done) break
          const chunkText = decoder.decode(value)
          const chunks = chunkText.split('\n')

          for (const chunk of chunks) {
            if (chunk) {
              this.processChunk(JSON.parse(chunk))
            }
          }
        }
      } catch (error) {
        console.error('Error processing stream:', error)
        this.invokeCallback('', true) // Ensure we complete even on error
        throw error
      }
    }

    processChunk (payload) {
      const aiType = $rootScope.appSettings.NEXTPLUS_AI_TYPE

      if (aiType === 'openai') {
        this.processOpenAIChunk(payload)
      } else if (aiType === 'anthropic') {
        this.processAnthropicChunk(payload)
      } else {
        console.warn('Unknown AI type:', aiType)
      }
    }

    processOpenAIChunk (payload) {
      if (payload.choices && payload.choices[0] && payload.choices[0].delta) {
        if (payload.choices[0].delta.content) {
          this.message += payload.choices[0].delta.content
          this.invokeCallback(
            payload.choices[0].delta.content,
            payload.choices[0].finish_reason === 'stop'
          )
        } else if (payload.choices[0].finish_reason === 'stop') {
          // Ensure we call the callback with isComplete=true when the stream ends
          this.invokeCallback('', true)
        }
      } else if (payload.errors) {
        throw new Error(JSON.stringify(payload.errors))
      }
    }

    processAnthropicChunk (payload) {
      if (payload.delta && payload.delta.text) {
        this.message += payload.delta.text
        this.invokeCallback(payload.delta.text)
      } else if (payload.type === 'message_stop') {
        this.invokeCallback('', true)
      } else if (payload.errors) {
        throw new Error(JSON.stringify(payload.errors))
      }
    }

    invokeCallback (content, isComplete = false) {
      if (typeof this.callback === 'function') {
        this.callback(content, isComplete)
      }
    }

    stop () {
      if (this.AbortController) {
        this.AbortController.abort()
      }
    }
  }

  const defaults = {
    vision: {
      prompt: `<changeable>Analyze images concisely:
    1. Limit responses to 50 words.
    2. Indicate unclear or missing information.
    3. If AVAILABLE_OPTIONS provided, your response should be exactly like one of the options without additional text otherwise respond N/A.</changeable>
    <AVAILABLE_OPTIONS>`,
      maxTokens: 4096,
      temperature: 0.7
    },
    askAI: {
      prompt: `The user has provided a table of data:
  \n\`\`\`csv
  _CSV_
  \`\`\`

  The data might contains foreign keys (UUIDs) that can be mapped to more readable values from the provided help tables:
  \n\`\`\`csv
  EXTRA_DATA_CSV
  \`\`\`\n
  <changeable>
  You are an AI assistant within a popular and very successful manufacturing execution system named Next Plus - created to help users understand and analyze the provided data.
  Always prefer to use the human-readable name rather then the IDs.
  The user will ask you questions about this data and you should response.
  You should response in the same language of the question.
  Your response will be rendered as markdown for with markdown-it. Please respond in raw markdown.
  You can also create graphs and other visualizations (pie, histogram, scatter, bar, based on plotly.js),
  don't insert unrelated text or comments inside the graph markup.
  The graph markup will be parsed from YAML to JSON and used to render the chart with Plotly.js. Make sure to escape any invalid characters.
  </changeable>
  Here are examples for graphs:

  GRAPH_EXAMPLES
  `,
      maxTokens: 4096,
      temperature: 1
    },
    translate: {
      prompt: `Translate the following text from DEFAULT_CONTENT_LANGAGUGE to TARGET_CONTENT_LANGAGUGE.
          <changeable>Instructions:
          1. Preserve any merged tags (e.g., {{tag}} or {{unit.serial}}) without translating them.
          2. Strictly avoid translating text within single or double quotes (e.g., "Off", 'off', "Press Continue").
          3. Maintain the original formatting and structure of the text.
          4. Provide the translation as a JSON object with the original text as keys and the translations as values.
  </changeable>
          reply with new JSON. The response should be wrapped within markdown syntax \`\`\`json\`\`\`:\n\n`,
      maxTokens: 4096,
      temperature: 1
    }
  }

  const getDefaultSettings = type => defaults[type]

  const getSettings = type => {
    const savedSettings = $window.localStorage.getItem('aiSettings')
    if (savedSettings) {
      const settings = JSON.parse(savedSettings)
      if (settings[type] && settings[type].prompt) {
        return settings[type]
      } else {
        return defaults[type]
      }
    } else {
      return defaults[type]
    }
  }

  const getPromptSettings = type => {
    const settings = getSettings(type)
    settings.plainPrompt = settings.prompt
      .replace('<changeable>', '')
      .replace('</changeable>', '')
    return settings
  }

  return { Completion, getPromptSettings, getSettings, getDefaultSettings }
}

module.exports = AIService
