/* global _ */
const { getMapLink } = require('root/../common/services/field-helper.js')
/** @ngInject */
function FieldUtilsService ($translate, htmlWork, DateTimeFormatService) {
  const nonFilterableTypes = [
    'upload',
    'button',
    'imageBasedForm',
    'subForm',
    'gpsInput'
  ]

  const lookupTypes = {
    NPT: 'NPT',
    MODELS: 'MODELS'
  }

  const getFieldHTMLValue = function getFieldHTMLValue (
    fields,
    fieldId,
    value,
    usersNamesById = [],
    direction = 'row',
    fullHTML = true
  ) {
    let html
    try {
      const fieldObject = fields.find(f => f.id === fieldId)
      html = `<span>${!_.isNil(value) ? htmlWork.htmlEncode(value) : ''}</span>`
      if (
        !_.isNil(value) ||
        (_.isNil(value) && fieldObject && fieldObject.type === 'checkbox')
      ) {
        if (fieldObject) {
          switch (fieldObject.type) {
            case 'datePicker':
              html = _.isNil(value)
                ? '--'
                : `<span>${DateTimeFormatService.formatDateTime(
                    value,
                    'date'
                  )}</span>`
              break
            case 'dateTimePicker':
              html = _.isNil(value)
                ? '--'
                : `<span>${DateTimeFormatService.formatDateTime(
                    value,
                    'dateTime'
                  )}</span>`
              break
            case 'checkbox':
              {
                let checkboxValue = 'icon-close'
                if (value === true || value === 'true') {
                  checkboxValue = 'icon-check'
                }
                html = `<div layout="row" layout-align="start center"><md-icon class="s20" md-font-icon="${checkboxValue}"></md-icon></div>`
              }
              break
            case 'upload':
            case 'button':
              if (typeof value === 'string') {
                html = `<div layout="column" layout-align="center center">
                          <img style="max-width:10rem" 
                              md-lightbox 
                              lightbox-src="api/Resources/${htmlWork.htmlEncode(
                                value
                              )}/0/0" 
                              src="api/Resources/${htmlWork.htmlEncode(
                                value
                              )}/medium/0"/>
                          <a target="_blank" href="/api/Resources/download/${htmlWork.htmlEncode(
                            value
                          )}">
                              <span translate="COMMON.DOWNLOAD"></span>
                          </a>
                      </div>`
              } else {
                html = `<div layout="column" layout-align="center center">`
                value.forEach(resourceId => {
                  html += `<div layout="column" layout-align="center center">
                              <img style="max-width:10rem" 
                                  md-lightbox 
                                  lightbox-src="api/Resources/${htmlWork.htmlEncode(
                                    resourceId
                                  )}/0/0" 
                                  src="api/Resources/${htmlWork.htmlEncode(
                                    resourceId
                                  )}/medium/0"/>
                              <a target="_blank" href="/api/Resources/download/${htmlWork.htmlEncode(
                                resourceId
                              )}">
                                  <span translate="COMMON.DOWNLOAD"></span>
                              </a>
                          </div>`
                })
                html += `</div>`
              }
              break
            case 'radio':
            case 'select':
              {
                const { options } = fieldObject
                const selectedOption =
                  options &&
                  options.find(
                    option =>
                      option.value === value ||
                      (option.id && option.id === value)
                  )
                if (selectedOption && selectedOption.resourceId) {
                  html = `<div layout="row" layout-align="start center">
                                <img  class="field-html-option-image" 
                                      md-lightbox 
                                      lightbox-src="api/Resources/${selectedOption.resourceId}/0/0" 
                                      lazy-load="api/Resources/${selectedOption.resourceId}/thumbnail/0"/>
                                ${html}
                              </div>`
                }
              }
              break
            case 'textarea':
              html = `<span>${
                !_.isNil(value)
                  ? htmlWork.nl2br(htmlWork.htmlEncode(value))
                  : ''
              }</span>`
              break
            case 'subForm':
              html = generateSubFormValueHTML(
                fields,
                value,
                fieldObject,
                direction,
                fullHTML
              )
              break
            case 'imageBasedForm':
              html = generateImageBasedFormValueHTML(
                fields,
                value,
                fieldObject,
                direction,
                fullHTML
              )
              break
            case 'gpsInput':
              html = generateGpsValueHTML(value)
              break
            case 'lookupSelect':
              if (typeof value === 'object') {
                html = generateLookupTableValueHTML(value, fieldObject)
              }
              break
            case 'selectUser':
              if (value) {
                if (typeof value === 'string') {
                  html = usersNamesById[value]
                } else {
                  html = value
                    .map(userId => {
                      return usersNamesById[userId]
                    })
                    .join(', ')
                }
              } else {
                html = ''
              }
              break
            case 'tinymce':
              html = value
              break
          }
        }
      }
    } catch (err) {
      console.error(err)
    }

    return html
  }

  const generateImageBasedFormValueHTML =
    function generateImageBasedFormValueHTML (
      fields,
      value,
      fieldObject,
      direction,
      fullHTML
    ) {
      let htmlValue = ''
      try {
        if (value && value.length) {
          const { fieldIds, images } = fieldObject
          const relatedFields = _.keyBy(
            fields.filter(field => fieldIds.includes(field.id)),
            'id'
          )
          switch (direction) {
            case 'column':
              htmlValue = '<div layout="column" layout-align="center start">'
              if (images && images.length === 1 && fullHTML) {
                const { resourceId } = images[0]
                htmlValue += `<img md-lightbox lightbox-src="api/Resources/${htmlWork.htmlEncode(
                  resourceId
                )}/0/0" 
                          src="api/Resources/${htmlWork.htmlEncode(
                            resourceId
                          )}/medium/0" 
                          style="cursor:pointer; width: 20rem; margin-bottom: 1rem;">`
              }
              htmlValue += `<table class="image-based-form-display-table image-based-form-display-table-column">
                        <thead>
                          <tr>
                            <th>
                              <span>
                                ${$translate.instant('MAP_EDITOR.LAYER')}
                              </span>
                            </th>
                            <th>
                                ${$translate.instant('COMMON.FIELD_NAME')}
                            </th>
                            <th>
                                ${$translate.instant('COMMON.VALUE')}
                            </th>
                            </tr>
                        </thead>
                        <tbody>`
              for (let idx = 0; idx < value.length; idx++) {
                const row = value[idx]
                const areaName = row.areaName || ''
                fieldIds.forEach((fieldId, idx) => {
                  htmlValue += '<tr>'
                  if (idx === 0) {
                    htmlValue += `<td style="text-align: start;font-weight: bold;" rowspan="${
                      fieldIds.length
                    }">
                                <span>${htmlWork.htmlEncode(areaName)}</span>
                              </td>`
                  }
                  const currentField = relatedFields[fieldId]
                  if (currentField) {
                    htmlValue += `<td><span>${htmlWork.htmlEncode(
                      currentField.title
                    )}</span></td>`
                    const valueObj =
                      currentField.type === 'lookupSelect' &&
                      currentField.tableType === lookupTypes.NPT
                        ? row.values.find(
                            o => o.fieldId === fieldId + '_lookup'
                          )
                        : row.values.find(o => o.fieldId === fieldId)
                    if (currentField && valueObj) {
                      const html = getFieldHTMLValue(
                        fields,
                        fieldId,
                        valueObj.value,
                        direction,
                        fullHTML
                      )
                      htmlValue += `<td>${html || ''}</td>`
                    } else {
                      htmlValue += '<td>--</td>'
                    }
                  }
                  htmlValue += '</tr>'
                })
              }
              break
            default: {
              htmlValue = '<div layout="row" layout-align="start center">'
              if (images && images.length === 1 && fullHTML) {
                const { resourceId } = images[0]
                htmlValue += `<img md-lightbox lightbox-src="api/Resources/${htmlWork.htmlEncode(
                  resourceId
                )}/0/0" 
                            src="api/Resources/${htmlWork.htmlEncode(
                              resourceId
                            )}/medium/0" 
                            style="cursor:pointer; width: 15rem; margin-inline-end: 1rem;">`
              }
              htmlValue += `<table class="image-based-form-display-table">
                        <thead><tr><th><span>${$translate.instant(
                          'MAP_EDITOR.LAYER'
                        )}</span></th>`
              fieldIds.forEach(fieldId => {
                const currentField = relatedFields[fieldId]
                if (currentField) {
                  htmlValue += `<th><span>${htmlWork.htmlEncode(
                    currentField.title
                  )}</span></th>`
                }
              })
              htmlValue += '</tr></thead><tbody>'
              for (let idx = 0; idx < value.length; idx++) {
                const row = value[idx]
                htmlValue += `<tr><td>${htmlWork.htmlEncode(
                  row.areaName || ''
                )}</td>`
                for (let f = 0; f < fieldIds.length; f++) {
                  const fieldId = fieldIds[f]
                  const valueObj =
                    relatedFields[fieldId].type === 'lookupSelect'
                      ? row.values.find(o => o.fieldId === fieldId + '_lookup')
                      : row.values.find(o => o.fieldId === fieldId)
                  if (relatedFields[fieldId] && valueObj) {
                    const html = getFieldHTMLValue(
                      fields,
                      fieldId,
                      valueObj.value,
                      direction,
                      fullHTML
                    )
                    htmlValue += `<td>${html || ''}</td>`
                  } else if (relatedFields[fieldId]) {
                    htmlValue += '<td>--</td>'
                  }
                }
                htmlValue += '</tr>'
              }
            }
          }

          htmlValue += '</tbody></table></div>'
        }
      } catch (err) {
        console.error(err)
      }
      return htmlValue
    }

  const generateLookupTableValueHTML = function generateLookupTableValueHTML (
    value,
    fieldObject
  ) {
    let htmlValue = ''
    if (value) {
      const { fieldIds, table } = fieldObject
      const columnsbyId = _.keyBy(
        table.columns.filter(column => fieldIds.includes(column.id)),
        'id'
      )
      const values = _.pick(value, fieldIds)
      htmlValue = '<div layout="column" layout-align="center start">'
      htmlValue += `<table class="sub-form-display-table" style="border-collapse: collapse;">
                          <thead>
                          <tr>`
      Object.keys(columnsbyId).forEach(columnId => {
        htmlValue += `<th style="background: #c8c8c8; border: 1px solid #d8d8d8; text-align: center; padding: 5px;">
            <span>${columnsbyId[columnId].name}</span>
          </th>`
      })
      htmlValue += `</tr>
                        </thead>
                        <tbody>`

      fieldIds.forEach(fieldId => {
        let currentField = values[fieldId]
        currentField =
          currentField === true
            ? 'true'
            : currentField === false
            ? 'false'
            : currentField
        if (currentField) {
          htmlValue += `<td style="text-align: start;font-weight: bold; border: 1px solid #d8d8d8;">
                                <span>${currentField}</span>
                              </td>`
        } else {
          htmlValue += `<td style="text-align: start;font-weight: bold; border: 1px solid #d8d8d8;">
                                <span>${''}</span>
                              </td>`
        }
      })

      htmlValue += '</tbody></table></div>'
    }
    return htmlValue
  }

  const generateGpsValueHTML = function generateGpsValueHTML (value) {
    let htmlValue = ''
    if (typeof value.toJSON === 'function') {
      value = value.toJSON()
    }

    if (value && Object.keys(value).length > 0) {
      const latitude = htmlWork.htmlEncode(value.latitude)
      const longitude = htmlWork.htmlEncode(value.longitude)
      htmlValue += `<a
            href="${getMapLink(latitude, longitude)}" target="_blank">
        ${$translate.instant('COMMON.LATITUDE')}:
        ${latitude}, 
        ${$translate.instant('COMMON.LONGITUDE')}:
        ${longitude}
        </a>`
    }
    return htmlValue
  }

  const generateSubFormValueHTML = function generateSubFormValueHTML (
    fields,
    subFormValue,
    fieldObject,
    direction,
    fullHTML,
    numbered = true
  ) {
    let htmlValue = ''
    if (subFormValue && subFormValue.length) {
      const fieldIds = fieldObject.fieldIds
      const relatedFields = _.keyBy(
        fields.filter(field => fieldIds.includes(field.id)),
        'id'
      )
      switch (direction) {
        case 'column':
          htmlValue = '<div layout="column" layout-align="center start">'
          htmlValue += `<table class="sub-form-display-table" style="border-collapse: collapse;">
                          <thead>
                          <tr>`
          if (numbered) {
            htmlValue += `<th style="background: #c8c8c8; border: 1px solid #d8d8d8; text-align: center; padding: 5px;">
            <span>#</span>
          </th>`
          }
          htmlValue += `<th style="background: #c8c8c8; border: 1px solid #d8d8d8; text-align: center; padding: 5px;">
                              <span>${$translate.instant(
                                'COMMON.FIELD_NAME'
                              )}</span>
                            </th>
                            <th style="background: #c8c8c8; border: 1px solid #d8d8d8; text-align: center; padding: 5px;">
                              <span>${$translate.instant(
                                'COMMON.VALUE'
                              )}</span> 
                            </th>
                          </tr>
                        </thead>
                        <tbody>`
          for (let idx = 0; idx < subFormValue.length; idx++) {
            const valueIndex = idx + 1
            const row = subFormValue[idx]
            fieldIds.forEach((fieldId, j) => {
              htmlValue += '<tr>'
              if (j === 0 && numbered) {
                htmlValue += `<td style="text-align: start;font-weight: bold; border: 1px solid #d8d8d8;" rowspan="${fieldIds.length}">
                                  <span>${valueIndex}</span>
                                </td>`
              }
              const currentField = relatedFields[fieldId]
              const value =
                currentField.type === 'lookupSelect'
                  ? row[fieldId + '_lookup']
                  : row[fieldId]
              if (currentField) {
                htmlValue += `<td style="text-align: start;font-weight: bold; border: 1px solid #d8d8d8;">
                                <span>${htmlWork.htmlEncode(
                                  currentField.title
                                )}</span>
                              </td>`
                const html = getFieldHTMLValue(
                  fields,
                  fieldId,
                  value,
                  direction,
                  fullHTML
                )
                htmlValue += `<td style="border: 1px solid #d8d8d8;">${
                  html || ''
                }</td>`
              }
              htmlValue += '</tr>'
            })
          }
          break
        default: {
          htmlValue = '<div layout="column" layout-align="center start">'
          htmlValue += `<table class="sub-form-display-table">
                          <thead><tr><th><span>#</span></th>`
          fieldIds.forEach(fieldId => {
            const currentField = relatedFields[fieldId]
            if (currentField) {
              htmlValue += `<th><span>${htmlWork.htmlEncode(
                currentField.title
              )}</span></th>`
            }
          })
          htmlValue += '</tr></thead><tbody>'
          for (let idx = 0; idx < subFormValue.length; idx++) {
            const row = subFormValue[idx]
            htmlValue += `<tr><td>${idx + 1}</td>`
            for (let f = 0; f < fieldIds.length; f++) {
              const fieldId = fieldIds[f]
              if (relatedFields[fieldId]) {
                const html = getFieldHTMLValue(
                  fields,
                  fieldId,
                  row[fieldId],
                  direction,
                  fullHTML
                )
                htmlValue += `<td>${html || ''}</td>`
              }
            }
            htmlValue += '</tr>'
          }
        }
      }
      htmlValue += '</tbody></table></div>'
    } else {
      htmlValue = $translate.instant('COMMON.EMPTY_SUB_FORM')
    }
    return htmlValue
  }

  return {
    lookupTypes,
    nonFilterableTypes,
    getFieldHTMLValue,
    generateSubFormValueHTML
  }
}

module.exports = FieldUtilsService
