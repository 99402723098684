/* global _ */
const jsonLogic = require('json-logic-js')
jsonLogic.add_operation('checked', a => {
  return a === 'true' || a === true
})
jsonLogic.add_operation('not_checked', a => {
  return a === 'false' || !a
})
jsonLogic.add_operation('<', (a, b, c) => {
  if (a === null || b === null) return false
  return c === undefined ? a < b : a < b && b < c
})
jsonLogic.add_operation('<=', (a, b, c) => {
  if (a === null || b === null) return false
  return c === undefined ? a <= b : a <= b && b <= c
})
jsonLogic.add_operation('>', (a, b, c) => {
  if (a === null || b === null) return false
  return a > b
})
jsonLogic.add_operation('>=', (a, b, c) => {
  if (a === null || b === null) return false
  return a >= b
})
jsonLogic.add_operation('isNull', a => {
  return typeof a === 'undefined' || a === null || a === ''
})
jsonLogic.add_operation('isNotNull', a => {
  return typeof a !== 'undefined' && a !== null && a !== ''
})

jsonLogic.add_operation('changes_to', function (currentValue, changedTo) {
  if (currentValue === changedTo[0]) {
    const fieldId = changedTo[1].split('FIELD_ID_')[1]
    const oldValue = _.get(this.oldFormData, fieldId)
    if (oldValue !== currentValue) {
      return true
    }
  }
  return false
})
jsonLogic.add_operation('changes', function (currentValue, changedTo) {
  if (this.isNewInstance) return false
  const fieldId = changedTo[1].split('FIELD_ID_')[1]
  const oldValue = _.get(this.oldFormData, fieldId)
  return oldValue !== currentValue
})

jsonLogic.add_operation('changes_from', function (currentValue, changedTo) {
  if (currentValue !== changedTo[0]) {
    const fieldId = changedTo[1].split('FIELD_ID_')[1]
    const oldValue = _.get(this.oldFormData, fieldId)
    if (oldValue === changedTo[0]) {
      return true
    }
  }
  return false
})
jsonLogic.add_operation('isBetween', (val, minMax) => {
  if (val === null || minMax[0] === null || minMax[1] === null) return false
  const min = minMax[0]
  const max = minMax[1]
  return min <= val && val <= max
})
jsonLogic.add_operation('isNotBetween', (val, minMax) => {
  if (minMax[0] === null || minMax[1] === null) return false
  if (val === null) return true
  const min = minMax[0]
  const max = minMax[1]
  return val > max || val < min
})
jsonLogic.add_operation('before', (dateA, dateB) => {
  if (_.isNil(dateA) || _.isNil(dateB)) return false
  dateA = new Date(dateA).getTime()
  dateB = new Date(dateB).getTime()
  return dateA < dateB
})
jsonLogic.add_operation('after', (dateA, dateB) => {
  if (_.isNil(dateA) || _.isNil(dateB)) return false
  dateA = new Date(dateA).getTime()
  dateB = new Date(dateB).getTime()
  return dateA > dateB
})
jsonLogic.add_operation('includes', (string, value) => {
  if (!string) return false
  return string.toString().indexOf(value) !== -1
})
jsonLogic.add_operation('includesPositive', (kitItems, SKUs) => {
  SKUs = SKUs.map(sku => sku.toString().trim())
  return (
    !_.isNil(kitItems) &&
    kitItems.find(
      item => SKUs.includes(item.sku) && item.quantity && item.quantity > 0
    )
  )
})
jsonLogic.add_operation('includesNegative', (kitItems, SKUs) => {
  SKUs = SKUs.map(sku => sku.toString().trim())
  return (
    !_.isNil(kitItems) &&
    kitItems.find(
      item => SKUs.includes(item.sku) && item.quantity && item.quantity < 0
    )
  )
})
