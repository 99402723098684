/*eslint-disable*/
import { mapOrder, isDate, stringToSlug } from 'app/helper'
import _, { update } from 'lodash'
const kendoSetLang = require('./kendo-lang')
const debug = require('debug')('nextplus:kendo-grid-helper-service')
const loopbackFilterConverter = require('loopback-filters')

/** @ngInject */
function KendoGridHelper(
  $rootScope,
  $translate,
  $state,
  $stateParams,
  $timeout,
  $compile,
  $interval,
  DateTimeFormatService,
  htmlWork,
  $window,
  MultiTranslateService
) {
  let gridCounter = 0

  let resizeCounter
  let resizeInterval
  let userFilter = {}
  const WILDCARDS = {
    ASTERISK: '*',
    QUESTION_MARK: '?'
  }
  const WILDCARDS_REPLACEMENTS = {
    ASTERISK: `\.\*`,
    QUESTION_MARK: '.'
  }
  const createComplexTypeMapping = function createComplexTypeMapping(columns) {
    return columns.reduce((result, current, index) => {
      if (
        current.uniqueId.includes('_') &&
        !current.uniqueId.includes('_lookup_')
      ) {
        const parts = current.uniqueId.split('_')
        parts.pop()
        const subFormId = parts.join('_')
        if (
          result.length === 0 ||
          result[result.length - 1].subFormId !== subFormId ||
          index - result[result.length - 1].length !==
            result[result.length - 1].index
        ) {
          result.push({
            index: index,
            columnId: current.uniqueId,
            subFormId,
            length: 1
          })
        } else {
          result[result.length - 1].length++
        }
      }
      return result
    }, [])
  }
  const calculateColumnAttributes = function calculateColumnAttributes(
    mappingArray,
    columnId
  ) {
    debug('calculateColumnAttributes')
    const emptyAttribute = { style: 'display:none;' }
    const currentSequence = mappingArray.find(c => c.columnId === columnId)
    if (!currentSequence) {
      return emptyAttribute
    }
    return { colspan: currentSequence.length, style: 'display: table-cell;' }
  }

  const escapeRegExp = function escapeRegExp(text) {
    debug('escapeRegExp')

    if (typeof text !== 'string') return text
    Object.keys(WILDCARDS).forEach(wildcard => {
      text = text.split(WILDCARDS[wildcard]).join(wildcard)
    })
    text = text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')
    Object.keys(WILDCARDS).forEach(wildcard => {
      text = text.split(wildcard).join(WILDCARDS_REPLACEMENTS[wildcard])
    })
    return text
  }
  let GridInstance = function (options, scope, initialColumns) {
    const gridInstance = {}
    let gridInstanceRef
    const gridId = `grid-${++gridCounter}`

    const highlightTextNode = function highlightTextNode(
      node,
      regex,
      template
    ) {
      if (node.nodeType === Node.TEXT_NODE) {
        const matches = node.nodeValue.match(regex)
        if (matches) {
          console.log('matches', matches)
          const replacementNode = document.createElement('span')
          const encodedHtml = htmlWork.htmlEncode(node.nodeValue)
          replacementNode.innerHTML = encodedHtml.replace(regex, match =>
            template.replace('${match}', match)
          )
          node.parentNode.replaceChild(replacementNode, node)
        }
      } else {
        node.childNodes.forEach(child =>
          highlightTextNode(child, regex, template)
        )
      }
    }
    const onFilter = async function onFilter() {
      const filters = this?.dataSource?._filter?.filters
      if (filters) {
        filters.forEach(filter => {
          if (['contains', 'equals'].includes(filter.operator)) {
            const regex = new RegExp(escapeRegExp(filter.value), 'gi')
            const template = `<mark>${'$'}{match}</mark>`
            const column = gridInstanceRef.instance.columns.find(
              column => column.field === filter.field
            )
            if (column) {
              gridInstanceRef.instance.tbody[0]
                .querySelectorAll(
                  `tr > td[data-column-id="${column.uniqueId}"]`
                )
                .forEach(td => {
                  highlightTextNode(td, regex, template)
                })
              if (gridInstanceRef.instance.lockedTable) {
                gridInstanceRef.instance.lockedTable[0]
                  .querySelectorAll(
                    `tr > td[data-column-id="${column.uniqueId}"]`
                  )
                  .forEach(td => {
                    highlightTextNode(td, regex, template)
                  })
              }
            }
          }
        })
      }
    }
    scope.$on('$destroy', () => {
      if (resizeInterval) {
        $interval.cancel(resizeInterval)
      }
      if (gridInstanceRef?.instance) {
        gridInstanceRef.instance.destroy()
        console.log('Destroy grid instance')
      }
    })
    return new Promise((resolve, reject) => {
      try {
        const defaults = {
          pageSize: 10,
          sortable: true,
          pdf: {
            allPages: true,
            avoidLinks: true,
            paperSize: 'A4',
            margin: {
              top: '2cm',
              left: '1cm',
              right: '1cm',
              bottom: '1cm'
            },
            landscape: true,
            repeatHeaders: true,
            template: `<div class="kendo-page-template">
              <div class="header">

              <div class="logo"><img src="https://127.0.0.1:8080/api/nextplusLogo"  /></div>
                <div class="pages">${$translate.instant(
                  'PAGES.PAGE'
                )} #: pageNum # ${$translate.instant(
              'PAGES.OF'
            )} #: totalPages #</div>
                <!-- Multi-page grid with automatic page breaking -->

              </div>
              <div class="footer">
              <div class="time">
              ${DateTimeFormatService.formatDateTime(new Date(), 'dateTime')}
              </div>
              <div class="pages">
                 ${$translate.instant(
                   'PAGES.PAGE'
                 )} #: pageNum # ${$translate.instant(
              'PAGES.OF'
            )} #: totalPages #
                 </div>
              </div>
            </div>`,
            scale: 0.8
          },
          noRecords: {
            template: function (e) {
              return `<no-results style="width:100%"></no-results>`
            }
          },
          reorderable: false,
          resizable: true,
          columnMenu: { sortable: true, columns: false },
          autoBind: true,
          loading: true,
          autoSize: true,
          ignoreParams: false,
          columnsFitted: false,
          stateName: $state.$current.name,
          serverSide: true,
          rowClicked: data => {},
          pageable: {
            refresh: true,
            pageSizes: [10, 20, 50, 100, 1000],
            buttonCount: 5
          },
          dataBound: function (e) {
            onFilter.bind(this)()
            debug('dataBound')
            if (gridInstanceRef.gridOptions.rowSelection) {
              gridInstanceRef.initializeEventListeners()
              gridInstanceRef.updateUI()
            }

            try {
              // Register event listeners for hovering (useful when using frozen column)
              const tableElm = e.sender.element[0]
              tableElm.querySelectorAll('tbody tr.k-master-row').forEach(tr => {
                tr.addEventListener('mouseover', e => {
                  let tr =
                    e.target.nodeName === 'TR'
                      ? e.target
                      : e.target.closest('tr.k-master-row')
                  tableElm
                    .querySelectorAll(`tbody tr[data-uid="${tr.dataset.uid}"]`)
                    .forEach(elm => {
                      elm.classList.add('hover')
                    })
                })

                tr.addEventListener('mouseout', e => {
                  let tr =
                    e.target.nodeName === 'TR'
                      ? e.target
                      : e.target.closest('tr.k-master-row')
                  tableElm
                    .querySelectorAll(`tbody tr[data-uid="${tr.dataset.uid}"]`)
                    .forEach(elm => {
                      elm.classList.remove('hover')
                    })
                })
              })
            } catch (ex) {
              console.error(ex)
            }

            const resizeGridFunction = function resizeGridFunction() {
              try {
                resizeCounter++
                if (gridInstanceRef.instance) {
                  gridInstanceRef.instance.resize(true)
                }
                if (resizeCounter > 31) {
                  resizeCounter = 0
                  if (resizeInterval) {
                    $interval.cancel(resizeInterval)
                  }
                }
              } catch (ex) {
                console.error(`Error in resizeGridFunction:`, ex)
              }
            }
            if (gridInstanceRef.instance) gridInstanceRef.instance.resize(true)

            const resizeGrid = function resizeGrid() {
              if (resizeInterval) {
                $interval.cancel(resizeInterval)
                resizeCounter = 0
              }
              resizeInterval = $interval(resizeGridFunction, 250)
            }
            resizeGrid()
            try {
              for (let i = 0; i < this.columns.length; i++) {
                if (!this.columns[i].width) this.autoFitColumn(i)
              }
              if (e.sender?.options?.autoSize) {
                resizeTable(e.sender.element[0])
              }
              if (gridInstance.bounded) {
                gridInstance.bounded(e)
              }
              this.options.columnsFitted = true
              if (
                this.options.extraDataBound &&
                typeof this.options.extraDataBound === 'function'
              ) {
                this.options.extraDataBound(e)
              }
            } catch (err) {
              console.error(err)
            }
          },
          columnResize: function (e) {
            debug('columnResize')

            if (!this.options.columnsFitted) return
            const { stateName, selectedViewId } = this.options
            const columnsWidth =
              JSON.parse(
                $window.localStorage.getItem(`columns-width-${stateName}`)
              ) || {}
            if (selectedViewId) {
              if (!columnsWidth[selectedViewId]) {
                columnsWidth[selectedViewId] = {}
              }
              columnsWidth[selectedViewId][e.column.uniqueId] = e.newWidth
              $window.localStorage.removeItem(`columns-width-${stateName}`)
              $window.localStorage.setItem(
                `columns-width-${stateName}`,
                JSON.stringify(columnsWidth)
              )
            }
          },
          filterable: { mode: 'menu, row' },
          cleanBaseFilter: {},
          baseFilter: {},
          rowSelection: false,
          selectionAttributes: [],
          selectionId: 'id',
          selectionChanged: function (selections) {
            console.log(`${selections.length} items selected`)
          }
        }
        async function betweenFilter(args) {
          const self = this
          var filterCell = args.element.parents('.k-filtercell')
          filterCell.empty()
          const dateScope = scope.$new(true)
          const field = self.field
          // Get filters from the URL
          let fromDate = null
          let toDate = null
          if (
            $stateParams.filter &&
            !gridInstanceRef.gridOptions.ignoreParams
          ) {
            const kendoFilter = JSON.parse($stateParams.filter)
            if (kendoFilter?.filter?.find) {
              fromDate = kendoFilter.filter.find(
                filter => filter.field === field && filter.operator === 'gte'
              )?.value
              toDate = kendoFilter.filter.find(
                filter => filter.field === field && filter.operator === 'lt'
              )?.value
            }
          }

          if (fromDate && toDate) {
            dateScope.ngModel = [fromDate, toDate]
          }
          dateScope.dateOpts = {
            mode: 'range',
            onChange: value => {
              const grid = gridInstanceRef.instance
              const dataSource = grid.dataSource
              let dataSourceFilter = dataSource.filter()
              if (typeof dataSourceFilter === 'undefined') {
                dataSourceFilter = { logic: 'and', filters: [] }
              }
              // check if filter with field already exists, if so remove it
              dataSourceFilter.filters = dataSourceFilter.filters.filter(
                filter => filter.field !== field
              )
              if (value.length === 2) {
                // add new filter
                dataSourceFilter.filters.push({
                  field,
                  operator: 'gte',
                  value: value[0]
                })
                const toDate = value[1]
                // Add one day
                toDate.setDate(toDate.getDate() + 1)
                dataSourceFilter.filters.push({
                  field,
                  operator: 'lt',
                  value: toDate
                })
                dataSource.filter(dataSourceFilter)
              } else if (value.length === 0) {
                dataSource.filter(dataSourceFilter)
              }
            }
          }

          const compiledElm = $compile(`
             <date-picker fp-opts="dateOpts" ng-model="ngModel"></date-picker>
          `)(dateScope)[0]
          filterCell.append(compiledElm)
        }
        const getColumnWithDefaults = function getColumnWithDefaults(column) {
          if (column.type === 'date') {
            column.filterable = {
              cell: { template: betweenFilter.bind(column) }
            }
            return column
          }
          return _.defaultsDeep(column, {
            type: 'string',
            filterable: {
              cell: {
                operator: 'contains',
                suggestionOperator: 'contains'
              }
            }
          })
        }

        const that = this
        const testRegex = new RegExp(/.+\.(.*)/m)
        const kendoShadow = _.cloneDeep(kendo)
        // https://github.com/akera-io/akera-loopback-demo/tree/master/client/lib
        kendoShadow.Loopback = kendoShadow.Loopback || {}
        kendoShadow.Loopback.Filter = {}
        jQuery.extend(true, kendoShadow.Loopback.Filter, {
          getClause: function (flt) {
            // convert bracket [ ] notation to dot notation
            flt.field = flt.field
              .replace(/\[([^\]]+)\]/g, '.$1')
              .replace(/"/g, '')
            // covert .UUID to UUID - for dynamic tables
            if (!testRegex.test(flt.field)) {
              flt.field = flt.field.replace(/\./g, '')
            }
            if (_.isString(flt.value)) {
              flt.value = flt.value.trim()
            }
            let clause = {}
            switch (flt.operator) {
              case 'eq':
                if (isDate(flt.value)) {
                  let fromObj = {}
                  const dateFrom = new Date(
                    moment(flt.value, 'YYYY-MM-DD')
                      .startOf('day')
                      .add(1, 'days')
                      .format('YYYY-MM-DD')
                  )
                  const fromOffSet = dateFrom.getTimezoneOffset() * 60000
                  fromObj[flt.field] = {
                    lt: new Date(dateFrom.getTime() + fromOffSet)
                  }
                  let toObj = {}
                  const dateTo = new Date(
                    moment(flt.value, 'YYYY-MM-DD')
                      .endOf('day')
                      .format('YYYY-MM-DD')
                  )
                  const toOffSet = dateTo.getTimezoneOffset() * 60000
                  toObj[flt.field] = {
                    gte: new Date(dateTo.getTime() + toOffSet)
                  }
                  clause = [fromObj, toObj]
                } else if (_.isString(flt.value)) {
                  // 28/06/2023 Asaf: I Don't know why we are using the like operation for 'eq' operator.
                  clause[flt.field] = {
                    like: `^${escapeRegExp(
                      flt.value
                    )}$` /*eslint-disable-line*/,
                    options: 'i'
                  }
                } else {
                  clause[flt.field] = flt.value
                }
                break
              case 'neq':
                if (isDate(flt.value)) {
                  let fromObj = {}
                  const dateFrom = new Date(
                    moment(flt.value, 'YYYY-MM-DD')
                      .startOf('day')
                      .add(1, 'days')
                      .format('YYYY-MM-DD')
                  )
                  const fromOffSet = dateFrom.getTimezoneOffset() * 60000
                  fromObj[flt.field] = {
                    gt: new Date(dateFrom.getTime() + fromOffSet)
                  }
                  let toObj = {}
                  const dateTo = new Date(
                    moment(flt.value, 'YYYY-MM-DD')
                      .endOf('day')
                      .format('YYYY-MM-DD')
                  )
                  const toOffSet = dateTo.getTimezoneOffset() * 60000
                  toObj[flt.field] = {
                    lt: new Date(dateTo.getTime() + toOffSet)
                  }
                  clause = { or: [fromObj, toObj] }
                } else {
                  clause[flt.field] = {
                    neq: flt.value
                  }
                }
                break
              case 'gte':
                clause[flt.field] = {
                  gte: flt.value
                }
                break
              case 'lte':
                clause[flt.field] = {
                  lte: flt.value
                }
                break
              case 'lt':
                clause[flt.field] = {
                  lt: flt.value
                }
                break
              case 'gt':
                clause[flt.field] = {
                  gt: flt.value
                }
                break
              case 'contains':
                clause[flt.field] = {
                  like:
                    '.*' +
                    escapeRegExp(flt.value) +
                    '.*' /*eslint-disable-line*/,
                  options: 'i'
                }
                break
              case 'doesnotcontain':
                clause[flt.field] = {
                  nlike: '.*' + flt.value + '.*' /*eslint-disable-line*/,
                  options: 'i'
                }
                break
              case 'startswith':
                clause[flt.field] = {
                  like: flt.value + '.*' /*eslint-disable-line*/,
                  options: 'i'
                }
                break
              case 'endswith':
                clause[flt.field] = {
                  like: '.*' + flt.value /*eslint-disable-line*/,
                  options: 'i'
                }
                break
              case 'isnull':
                clause[flt.field] = null
                break
              case 'isnotnull':
                clause[flt.field] = {
                  neq: null
                }
                break
              case 'isempty':
                clause = {
                  or: [
                    { [flt.field]: { exists: false } },
                    { [flt.field]: null },
                    { [flt.field]: '' }
                  ]
                }
                break
              case 'isnotempty':
                clause = [
                  {
                    [flt.field]: { exists: true },
                    [flt.field]: {
                      neq: null
                    }
                  },
                  {
                    [flt.field]: {
                      neq: ''
                    }
                  }
                ]
                break
              case 'in':
                clause = [
                  {
                    [flt.field]: {
                      in:
                        typeof flt.value === 'string'
                          ? flt.value.split(',')
                          : flt.value
                    }
                  }
                ]
                break
              default:
                console.error(
                  'Filter operator ' + flt.operator + ' is not supported.'
                )
            }
            return clause
          },
          recursiveFilter: function (filter) {
            const self = this
            const loopbackFilter = {}
            if (filter.filters) {
              loopbackFilter[filter.logic] = []
              filter.filters.forEach(function (flt) {
                let res = self.recursiveFilter(flt)
                if (_.isArray(res)) {
                  loopbackFilter[filter.logic] =
                    loopbackFilter[filter.logic].concat(res)
                } else {
                  loopbackFilter[filter.logic].push(res)
                }
              })
              return loopbackFilter
            } else return this.getClause(filter)
          },
          convert: function convert(filter, update) {
            const loopbackFilter = this.recursiveFilter(filter)
            return update === true
              ? loopbackFilter
              : {
                  where: loopbackFilter
                }
          }
        })
        const find = (resultFilter, options, self) => {
          return new Promise(async (resolve, reject) => {
            self.dataSource.loopbackFilter = resultFilter
            let findFilter = { filter: resultFilter }
            let counterFilter = { where: resultFilter.where || {} }
            if (resultFilter.extraData) {
              counterFilter.extraData = resultFilter.extraData
            }
            if (gridInstance.gridOptions.params) {
              findFilter = _.merge(findFilter, gridInstance.gridOptions.params)
              counterFilter = _.merge(
                counterFilter,
                gridInstance.gridOptions.params
              )
            }
            Promise.all([
              self.dataSource.options.find(findFilter).$promise,
              self.dataSource.options.count(counterFilter).$promise
            ]).then(([find, count]) => {
              $timeout(() => {
                const rs = find.map(record => {
                  gridInstance.gridOptions.columns.forEach(column => {
                    const hasValue = _.get(record, column.field)
                    if (_.isUndefined(hasValue)) {
                      _.set(record, column.field, '')
                    }
                  })
                  return record
                })
                rs[self.dataSource.options.schema.total] = count.count
                options.success(rs)
                resolve()
              }, 0)
            })
          })
        }
        let LoopbackTransport = function () {}
        jQuery.extend(true, LoopbackTransport.prototype, {
          read: function (options) {
            const self = this
            const filter = {
              language: $rootScope.currentLang
            }
            filter.limit = options.data.pageSize || null
            if (
              this.dataSource.options.serverPaging === true &&
              options.data.page &&
              options.data.page > 1
            ) {
              filter.offset = options.data.skip
            }
            if (
              self.dataSource.options.baseFilter &&
              self.dataSource.options.baseFilter.order
            ) {
              filter.order = self.dataSource.options.baseFilter.order
            }
            if (
              self.dataSource.options.baseFilter &&
              self.dataSource.options.baseFilter.extraData
            ) {
              filter.extraData = self.dataSource.options.baseFilter.extraData
            }
            if (
              self.dataSource.options.baseFilter &&
              self.dataSource.options.baseFilter.fields
            ) {
              filter.fields =
                self.dataSource.options.fields ||
                self.dataSource.options.baseFilter.fields
            }

            if (
              this.dataSource.options.serverSorting === true &&
              options.data.sort &&
              options.data.sort.length
            ) {
              filter.order = ''
              options.data.sort.forEach(function (sort) {
                sort.field = sort.field
                  .replace(/\[([^\]]+)\]/g, '.$1')
                  .replace(/"/g, '')
                // covert .UUID to UUID - for dynamic tables
                if (!testRegex.test(sort.field)) {
                  sort.field = sort.field.replace(/\./g, '')
                }
                filter.order = sort.field + ' ' + sort.dir.toUpperCase()
              })
            }
            let newFilter = _.cloneDeep(options.data.filter)
            if (
              newFilter &&
              newFilter.filters &&
              newFilter.filters.length > 0
            ) {
              newFilter.filters = newFilter.filters.map(filter1 => {
                if (
                  self.dataSource.options.selectFields.includes(filter1.field)
                ) {
                  const options = filter1.value.split(',')
                  if (options.length > 0) {
                    let orObj = {
                      logic: 'or',
                      filters: []
                    }
                    options.map(value => {
                      orObj.filters.push({
                        field: filter1.field,
                        operator: 'eq',
                        value: value
                      })
                    })
                    filter1 = orObj
                  }
                }
                return filter1
              })
            }
            if (!gridInstance.gridOptions.ignoreParams) {
              const fixFilter = filter => {
                if (filter?.filters) {
                  filter = filter.filters.map(fit => {
                    if (fit.operator === 'in')
                      if (typeof fit.value === 'string')
                        fit.value = fit.value.split(',')
                    return fit
                  })
                }

                return filter
              }
              $state.transitionTo(
                $state.$current,
                {
                  filter: JSON.stringify({
                    page: self.dataSource.page(),
                    pageSize: self.dataSource.pageSize(),
                    sort: self.dataSource.sort() || {},
                    filter: fixFilter(self.dataSource.filter()) || {},
                    group: self.dataSource.group() || []
                  })
                },
                {
                  location: 'replace',
                  inherit: true,
                  relative: $state.$current,
                  notify: false
                }
              )
            }
            if (this.dataSource.options.serverFiltering === true && newFilter) {
              jQuery.extend(
                true,
                filter,
                kendoShadow.Loopback.Filter.convert(newFilter, false)
              )
            }
            let resultFilter = _.cloneDeep(filter)
            if (
              self.dataSource.options.baseFilter &&
              self.dataSource.options.baseFilter.where
            ) {
              if (resultFilter.where) {
                if (resultFilter.where.and) {
                  resultFilter.where.and.push(
                    self.dataSource.options.baseFilter.where
                  )
                } else {
                  resultFilter.where.and = [
                    self.dataSource.options.baseFilter.where
                  ]
                }
              } else {
                resultFilter.where = self.dataSource.options.baseFilter.where
              }
            }
            if (
              self.dataSource.options.baseFilter &&
              self.dataSource.options.baseFilter.include
            ) {
              resultFilter.include = self.dataSource.options.baseFilter.include
            }
            if (
              resultFilter.where &&
              resultFilter.where.and &&
              resultFilter.where.and.length === 0
            ) {
              resultFilter.where = {}
            }
            if (
              self.dataSource.options.baseFilter &&
              self.dataSource.options.baseFilter.deleted
            ) {
              resultFilter.deleted = self.dataSource.options.baseFilter.deleted
            }
            userFilter = _.pick(resultFilter, 'where')
            find(resultFilter, options, self)
          }
        })

        const KendoNextplusDataSource = kendoShadow.data.DataSource.extend({
          init: function (options) {
            debug('KendoNextplusDataSource.init')
            var transport = new LoopbackTransport()
            transport.dataSource = this
            options.transport = transport
            options.filterable = true
            options.baseFilter = options.baseFilter || {}
            options.cleanBaseFilter = options.cleanBaseFilter || {}
            options.selectFields = options.selectFields || []
            options.find = options.find
              ? options.find
              : () => {
                  console.error('find fn not found')
                }
            options.count = options.count
              ? options.count
              : () => {
                  console.error('Count fn not found')
                }
            options.serverFiltering = options.serverFiltering || true
            options.serverPaging = options.serverPaging || true
            options.serverSorting = options.serverSorting || true
            options.schema = options.schema || {}
            options.schema.total = options.schema.total || 'count'
            kendoShadow.data.DataSource.fn.init.call(this, options)
          }
        })
        const buildGridSchema = function buildGridSchema(options) {
          debug('KendoNextplusDataSource.buildGridSchema')

          const schema = { model: { fields: {} } }
          options.columns.forEach(column => {
            const field = column.field
            schema.model.fields[field] = {
              type: column.type
            }
          })
          return schema
        }
        const fixColumnTitle = function fixColumnTitle(opt) {
          opt.columns = opt.columns.map(column => {
            if (column.translateCode) {
              column.title = $translate.instant(
                column.translateCode,
                {},
                null,
                null,
                'sceParameters'
              )
            }
            if (!opt.encodeTitles) {
              column.title = htmlWork.htmlEncode(column.title)
            }
            return column
          })
          return opt
        }
        const resizeTable = function resizeTable(elm) {
          debug('resizeTable')

          try {
            const kGrid = elm.parentElement.parentElement
            const kGridParent = kGrid.parentElement
            // kGridParent element height excluding children elements that are not <grid-wrapper>
            const kGridParentUsedHeight = Array.from(
              kGridParent.children
            ).reduce((acc, child) => {
              if (child.tagName === 'GRID-WRAPPER') return acc
              return acc + child.clientHeight
            }, 0)

            const marginTop = 24
            const toolbarHeight = 59
            // const kGrid = parent.querySelector('grid-wrapper')
            const headerHeight =
              kGrid.querySelector('.k-grid-header').clientHeight
            const pagerHeight =
              kGrid.querySelector('.k-pager-wrap')?.clientHeight || 0
            let targetHeight =
              kGridParent.clientHeight -
              kGridParentUsedHeight -
              marginTop -
              toolbarHeight -
              headerHeight -
              5 -
              pagerHeight
            // Get body margin-top and minus it from the target height
            const body = document.querySelector('body')
            const bodyMarginTop = parseInt(
              window.getComputedStyle(body).marginTop
            )
            if (bodyMarginTop) targetHeight -= bodyMarginTop
            if (targetHeight < 250) targetHeight = 250
            kGrid.querySelector('.k-grid-content').style.height =
              targetHeight + 'px'
            if (kGrid.querySelector('.k-grid-content-locked') !== null) {
              kGrid.querySelector('.k-grid-content-locked').style.height =
                targetHeight + 'px'
            }
            // window.addEventListener(
            //   'resize',
            //   function (event) {
            //     // resizeTable()
            //   },
            //   true
            // )
          } catch (err) {
            console.error(err)
          }
        }
        const fixColumnKeys = function fixColumnKeys(opt) {
          debug('fixColumnKeys')

          const model = opt.model
          if (model) {
            opt.columns = opt.columns.map(column => {
              column.field = MultiTranslateService.createKeysForGrid(
                model,
                $rootScope.currentLang,
                column.field
              )
              return column
            })
          }
          return opt
        }
        const initialColumnSetup = function initialColumnSetup(
          opt,
          initialColumns
        ) {
          const currentColumns = _.cloneDeep(opt.columns).map(column => {
            const index = initialColumns.findIndex(
              c => c.uniqueId === column.uniqueId
            )
            if (index === -1) {
              column.hidden = true
            } else {
              column.hidden = false
              if (initialColumns[index] && initialColumns[index].width) {
                column.width = initialColumns[index].width
              }
              if (initialColumns[index] && initialColumns[index].attributes) {
                column.attributes = initialColumns[index].attributes
              }
            }
            return column
          })
          const visibleColumns = currentColumns.filter(c => !c.hidden)
          const nonVisibleColumns = currentColumns.filter(c => c.hidden)
          opt.columns = mapOrder(
            visibleColumns,
            initialColumns.map(c => c.uniqueId),
            'uniqueId'
          ).concat(nonVisibleColumns)
          return opt
        }
        const createDataSource = function createDataSource(ref, options) {
          debug('createDataSource')

          if (!options.serverSide) {
            const originalData = options.originalData || options.data
            let data = _.cloneDeep(options.originalData || options.data)
            if (
              options.baseFilter &&
              !_.isEmpty(options.baseFilter) &&
              !_.isEmpty(options.baseFilter.where)
            ) {
              data = loopbackFilterConverter(data, options.baseFilter)
            } else if (gridInstance.gridOptions.originalData) {
              data = originalData
            }
            return {
              schema: buildGridSchema(ref.gridOptions),
              baseFilter: options.baseFilter || {},
              cleanBaseFilter: options.cleanBaseFilter || {},
              selectFields: _.map(
                ref.gridOptions.columns.filter(column => column.multi),
                'field'
              ),
              options: {},
              serverFiltering: false,
              serverPaging: false,
              serverSorting: false,
              data,
              originalData,
              pageSize: options.pageSize || ref.gridOptions.pageSize
            }
          }
          return new KendoNextplusDataSource({
            schema: buildGridSchema(ref.gridOptions),
            pageSize: options.pageSize || ref.gridOptions.pageSize,
            find: options.find,
            count: options.count,
            selectFields: _.map(
              ref.gridOptions.columns.filter(column => column.multi),
              'field'
            ),
            baseFilter: options.baseFilter,
            cleanBaseFilter: options.cleanBaseFilter || {}
          })
        }
        const parseFilterDates = function parseFilterDates(filter, fields) {
          debug('parseFilterDates')
          if (Array.isArray(filter)) {
            for (let i = 0; i < filter.length; i++) {
              parseFilterDates(filter[i], fields)
            }
          } else {
            if (filter.filters) {
              for (let i = 0; i < filter.filters.length; i++) {
                parseFilterDates(filter.filters[i], fields)
              }
            } else {
              if (fields[filter.field]?.type == 'date') {
                filter.value = kendo.parseDate(filter.value)
              }
            }
          }
        }
        const columnSetup = function columnSetup(columns) {
          debug('columnSetup')

          if (gridInstance.gridOptions.rowSelection) {
            columns.unshift({
              uniqueId: 'checkbox-column',
              field: 'selected',
              width: 50,
              headerTemplate: `<input type="checkbox" data-grid-id="${gridId}" data-select-all="true">`,
              trustedTemplate: dataItem => {
                const isSelected = gridInstanceRef.selectedIds.has(dataItem.id)
                return `<input type="checkbox" data-grid-id="${gridId}" data-selection-id="${htmlWork.htmlEncode(
                  dataItem[gridInstance.gridOptions.selectionId]
                )}" ${isSelected ? 'checked' : ''}>`
              },
              sortable: false,
              filterable: false
            })
          }

          columns = columns.map(column => {
            column = getColumnWithDefaults(column)
            if (column.template && !column.trustedTemplate) {
              const originalTemplate = column.template
              column.template = column.trustedTemplate = function template(
                row,
                data
              ) {
                return htmlWork.htmlEncode(
                  originalTemplate.apply(column, [row, data])
                )
              }
            } else if (!column.template && column.trustedTemplate) {
              column.template = column.trustedTemplate
            }
            if (column.type === 'boolean' && !column.template) {
              column.template = (row, data) => {
                let icon = 'icon-minus'
                if (row[column.field] === true) {
                  icon = 'icon-check'
                } else if (row[column.field] === false) {
                  icon = 'icon-close'
                }
                return `<div layout="row" layout-align="start center">
                          <md-icon md-font-icon="${icon}" class="s20"></md-icon>
                        </div>`
              }
            }
            if (
              column.filterable === true ||
              (column.filterable && column.filterable.cell)
            ) {
              if (column.filterable === true) {
                column.filterable = {}
              }
              if (!column.filterable.cell) {
                column.filterable.cell = {}
              }
              if (
                column.type !== 'date' &&
                column.type !== 'boolean' &&
                column.type !== 'menu'
              ) {
                if (!column.filterable.cell.template) {
                  column.filterable.cell.template = function (e) {
                    e.element.addClass('k-textbox')
                    e.element[0].style.width = '100%'
                  }
                }
              }
              if (column.filterable.cell.angularTemplate) {
                column.filterable.cell.template = function (args) {
                  const wrapper = $(args.element[0]).parent()
                  $(args.element[0]).remove()

                  const compiledElm = $compile(
                    column.filterable.cell.angularTemplate
                  )(scope)[0]
                  console.log(compiledElm)
                  const compiledHtml = $(compiledElm).html()
                  console.log(compiledHtml)
                  wrapper.append(compiledHtml)
                }
              }

              if (column.type === 'upload') {
                column.type = 'string'
                column.filterable = {
                  operators: {
                    string: {
                      isempty: $translate.instant('COMMON.IS_EMPTY'),
                      isnotempty: $translate.instant('COMMON.IS_NOT_EMPTY')
                    }
                  },
                  cell: {
                    showOperators: true,
                    operator: 'eq',
                    suggestionOperator: 'eq',
                    template: function (args) {
                      args.element[0].style.display = 'none'
                    }
                  }
                }
              }
              if (column.type === 'number') {
                column.filterable.cell.operator = 'eq'
              }

              column.filterable.cell.minLength = 500
              if (!column.filterable.operators)
                column.filterable.operators = {
                  array: {
                    contains:
                      kendo.ui.FilterCell.fn.options.operators.string.contains,
                    doesnotcontain:
                      kendo.ui.FilterCell.fn.options.operators.string
                        .doesnotcontain,
                    endswith:
                      kendo.ui.FilterCell.fn.options.operators.string.endswith,
                    startswith:
                      kendo.ui.FilterCell.fn.options.operators.string
                        .startswith,
                    eq: kendo.ui.FilterCell.fn.options.operators.string.eq,
                    neq: kendo.ui.FilterCell.fn.options.operators.string.neq
                  },
                  string: {
                    contains:
                      kendo.ui.FilterCell.fn.options.operators.string.contains,
                    doesnotcontain:
                      kendo.ui.FilterCell.fn.options.operators.string
                        .doesnotcontain,
                    endswith:
                      kendo.ui.FilterCell.fn.options.operators.string.endswith,
                    startswith:
                      kendo.ui.FilterCell.fn.options.operators.string
                        .startswith,
                    eq: kendo.ui.FilterCell.fn.options.operators.string.eq,
                    neq: kendo.ui.FilterCell.fn.options.operators.string.neq
                  },
                  date: {
                    eq: kendo.ui.FilterCell.fn.options.operators.date.eq,
                    gt: kendo.ui.FilterCell.fn.options.operators.date.gt,
                    gte: kendo.ui.FilterCell.fn.options.operators.date.gte,
                    lt: kendo.ui.FilterCell.fn.options.operators.date.lt,
                    lte: kendo.ui.FilterCell.fn.options.operators.date.lte,
                    neq: kendo.ui.FilterCell.fn.options.operators.date.neq
                  },
                  enums: {
                    eq: kendo.ui.FilterCell.fn.options.operators.enums.eq,
                    neq: kendo.ui.FilterCell.fn.options.operators.enums.neq
                  },
                  number: {
                    eq: kendo.ui.FilterCell.fn.options.operators.number.eq,
                    gt: kendo.ui.FilterCell.fn.options.operators.number.gt,
                    gte: kendo.ui.FilterCell.fn.options.operators.number.gte,
                    lt: kendo.ui.FilterCell.fn.options.operators.number.lt,
                    lte: kendo.ui.FilterCell.fn.options.operators.number.lte,
                    neq: kendo.ui.FilterCell.fn.options.operators.number.neq
                  }
                }
              if (column.filterable.cell.template && !column.filterable.ui) {
                column.filterable.ui = function (element) {
                  const args = {
                    element
                  }
                  column.filterable.cell.template(args)
                }
              }
            }
            if (!column.attributes) {
              column.attributes = {}
            }

            column.attributes.class = `column-${stringToSlug(column.field)}`
            column.attributes['data-column-id'] = column.uniqueId
            column.attributes.dir = `auto`
            column.sendToAi =
              typeof column.sendToAi === 'undefined' ? true : column.sendToAi
          })
        }
        this.scope = scope
        this.options = options
        options = fixColumnTitle(options)
        options = fixColumnKeys(options)
        options = initialColumnSetup(options, initialColumns)
        gridInstance.gridOptions = this.gridOptions = _.defaultsDeep(
          options,
          defaults
        )
        gridInstance.gridOptions.selectionAttributes.unshift(
          gridInstance.gridOptions.selectionId
        )
        if (
          gridInstance.gridOptions.pageable.pageSizes &&
          !gridInstance.gridOptions.pageable.pageSizes.includes(
            gridInstance.gridOptions.pageSize
          )
        ) {
          gridInstance.gridOptions.pageable.pageSizes.push(
            gridInstance.gridOptions.pageSize
          )
          gridInstance.gridOptions.pageable.pageSizes.sort((a, b) => a - b)
        }
        this.gridOptions.language = $rootScope.currentLang
        if (kendoSetLang[$rootScope.currentLang])
          kendoSetLang[$rootScope.currentLang]()
        this.gridOptions.toolbar = options.toolbar
        columnSetup(this.gridOptions.columns)
        if (
          !_.isNil($stateParams.filter) &&
          $stateParams.filter !== '' &&
          !options.ignoreParams
        ) {
          const kendoFilter = JSON.parse($stateParams.filter)
          if (kendoFilter.pageSize) {
            this.gridOptions.pageSize = kendoFilter.pageSize
          }
        }

        this.gridOptions.dataSource = createDataSource(this, this.gridOptions)
        if (this.scope) {
          this.scope.$on('lang', (e, dir) => {
            location.reload()
          })
        }
        if (
          !_.isNil($stateParams.filter) &&
          $stateParams.filter !== '' &&
          !options.ignoreParams
        ) {
          const kendoFilter = JSON.parse($stateParams.filter)
          if (kendoFilter.filter && !_.isEmpty(kendoFilter.filter)) {
            parseFilterDates(
              kendoFilter.filter,
              gridInstance.gridOptions.dataSource.options.schema.model.fields
            )
            this.options.autoBind = options.autoBind = false
            options.dataSource.query(kendoFilter)
          }
        }
        gridInstanceRef = {
          addRow: function (row, index = 0) {
            const insertedRow = this.instance.dataSource.insert(index, row)
            this.instance.element[0]
              .querySelectorAll(`[data-uid="${insertedRow.uid}"]`)
              .forEach(el => {
                el.classList.add('newly-inserted-row')
              })
            return insertedRow
          },
          reloadData: function () {
            this.instance.dataSource.read()
          },
          changeColumnAttributes(columnId, attributes) {
            if (this.instance.columns.find(c => c.uniqueId === columnId)) {
              _.merge(
                this.instance.columns.find(c => c.uniqueId === columnId)
                  .attributes,
                attributes
              )
            }
          },
          setColumnsAndFilters: function (
            columns,
            selectedViewId,
            loopbackFilters
          ) {
            const that = this
            const cleanBaseFilter = _.cloneDeep(
              that.gridOptions.cleanBaseFilter || {}
            )
            if (
              !_.isEmpty(loopbackFilters) &&
              !_.isEmpty(cleanBaseFilter.where)
            ) {
              loopbackFilters = {
                and: [loopbackFilters, cleanBaseFilter.where]
              }
            } else if (!_.isEmpty(cleanBaseFilter.where)) {
              loopbackFilters = cleanBaseFilter.where
            }
            cleanBaseFilter.where = loopbackFilters
            that.gridOptions.dataSource.options.baseFilter = cleanBaseFilter
            that.gridOptions.baseFilter = cleanBaseFilter
            that.showHideColumns(columns)
            if (that.gridOptions.serverSide) {
              that.instance.setOptions({
                selectedViewId: selectedViewId,
                baseFilter: cleanBaseFilter
              })
            } else {
              that.instance.setOptions({
                selectedViewId: selectedViewId,
                baseFilter: cleanBaseFilter,
                dataSource: createDataSource(that, that.gridOptions)
              })
            }
          },
          setColumns: function (columns, selectedViewId) {
            that.showHideColumns(columns)
            that.instance.setOptions({ selectedViewId: selectedViewId })
          },
          showHideColumns: function (columns) {
            const that = this
            const currentColumns = _.cloneDeep(that.instance.columns)
            for (let i = 0; i < currentColumns.length; i++) {
              const columnId = currentColumns[i].uniqueId
              const column = that.instance.columns.find(
                c => c.uniqueId === columnId
              )
              const index = columns.findIndex(c => c.uniqueId === columnId)
              if (index === -1) {
                that.instance.hideColumn(column)
              } else {
                if (columns[index] && columns[index].width) {
                  column.width = columns[index].width
                }
                that.instance.reorderColumn(index, column)
                that.instance.showColumn(column)
              }
            }
          },
          setOptions: function (options) {
            debug('setOptions')
            options = _.defaultsDeep(options, defaults)
            gridInstance.gridOptions = that.gridOptions = options
            options = fixColumnTitle(options)
            columnSetup(options.columns)
            options.columns = options.columns.map(column => {
              column = getColumnWithDefaults(column)
              if (
                column.filterable &&
                column.filterable.cell &&
                !column.filterable.cell.template
              ) {
                column.filterable.cell.template = function (e) {
                  e.element.addClass('k-textbox')
                  e.element[0].style.width = '100%'
                }
              }

              return column
            })

            this.instance.setOptions({
              columns: options.columns,
              dataSource: createDataSource(that, options)
            })
          },
          setSelectedViewId: function (selectedViewId) {
            this.instance.setOptions({ selectedViewId: selectedViewId })
          },
          setLoading: function (boolean) {
            kendo.ui.progress(this.instance.element, boolean)
          },
          getFilters: function () {
            return userFilter
          },
          isBound: () =>
            new Promise(function (resolve) {
              gridInstance.bounded = resolve
            }),
          selectedViewId: this.selectedViewId,
          gridOptions: this.gridOptions,
          instance: this.instance
        }

        const SelectionManager = {
          selectedItems: [],
          selectedIds: new Set(),
          allSelected: false,
          toggleItem(dataItem, checked) {
            const selectionId = dataItem[gridInstance.gridOptions.selectionId]
            if (checked) {
              this.addItem(dataItem)
            } else {
              this.removeItem(selectionId)
            }
            this.updateUI()
          },

          addItem(dataItem) {
            const selectionId = dataItem[gridInstance.gridOptions.selectionId]
            if (!this.selectedIds.has(selectionId)) {
              const selectedAttributes =
                this.extractSelectedAttributes(dataItem)
              this.selectedIds.add(selectionId)
              this.selectedItems.push(selectedAttributes)
            }
          },

          removeItem(selectionId) {
            const index = this.selectedItems.findIndex(
              item => item[gridInstance.gridOptions.selectionId] === selectionId
            )
            if (index > -1) {
              this.selectedItems.splice(index, 1)
              this.selectedIds.delete(selectionId)
            }
          },

          extractSelectedAttributes(dataItem) {
            return gridInstance.gridOptions.selectionAttributes.reduce(
              (attrs, attr) => {
                attrs[attr] = dataItem[attr]
                return attrs
              },
              {}
            )
          },

          toggleAll(checked) {
            this.allSelected = checked
            const dataItems = gridInstanceRef.instance.dataSource.data()

            if (checked) {
              dataItems.forEach(this.addItem.bind(this))
            } else {
              this.clearSelection()
            }

            this.updateUI()
          },

          clearSelection() {
            if (
              this.selectedItems.length ===
              gridInstanceRef.instance.dataSource.total()
            ) {
              this.selectedItems = []
              this.selectedIds.clear()
            } else {
              const itemsToRemove = gridInstanceRef.instance.dataSource
                .data()
                .map(item => item[gridInstance.gridOptions.selectionId])
              this.selectedItems = this.selectedItems.filter(
                item =>
                  !itemsToRemove.includes(
                    item[gridInstance.gridOptions.selectionId]
                  )
              )
              itemsToRemove.forEach(id => this.selectedIds.delete(id))
            }
            this.allSelected = false
            this.updateUI()
          },

          selectAllResults() {
            const combinedFilter = this.getCombinedFilter()
            const findOptions = { filter: combinedFilter }

            gridInstance.gridOptions
              .find(findOptions)
              .$promise.then(results => {
                this.clearSelection()
                results.forEach(this.addItem.bind(this))
                this.allSelected = true
                this.updateUI()
              })
              .catch(error => {
                console.error('Error selecting all results:', error)
              })
          },

          getCombinedFilter() {
            const currentFilter = gridInstanceRef.instance.dataSource.filter()
            const baseFilter = gridInstance.gridOptions.baseFilter || {}
            let combinedFilter = { ...baseFilter }

            if (currentFilter) {
              combinedFilter.where = {
                and: [
                  baseFilter.where || {},
                  kendoShadow.Loopback.Filter.convert(currentFilter, true).where
                ]
              }
            }

            combinedFilter.fields = {
              [gridInstance.gridOptions.selectionId]: true,
              ...Object.fromEntries(
                gridInstance.gridOptions.selectionAttributes.map(attr => [
                  attr,
                  true
                ])
              )
            }

            return combinedFilter
          },

          updateUI() {
            this.updateUiCheckboxes()
            this.updateSelectionSummary()
            gridInstance.gridOptions.selectionChanged(this.selectedItems)
            this.updateSelectAllCheckbox()
          },

          updateSelectAllCheckbox() {
            const totalItems = gridInstanceRef.instance.dataSource.data().length
            const checkboxes =
              gridInstanceRef.instance.element[0].querySelectorAll(
                'input[type="checkbox"][data-selection-id]'
              )
            const selectedItems = [...checkboxes].filter(
              checkbox => checkbox.checked
            ).length
            const selectAllCheckbox =
              gridInstanceRef.instance.element[0].querySelector(
                'input[type="checkbox"][data-select-all]'
              )

            selectAllCheckbox.indeterminate =
              selectedItems > 0 && selectedItems < totalItems
            selectAllCheckbox.checked = selectedItems === totalItems
          },

          updateSelectionSummary() {
            const selectedItems = this.selectedItems.length
            const totalItems = gridInstanceRef.instance.dataSource.total()
            let summaryElement = this.getOrCreateSummaryElement()

            if (selectedItems > 0) {
              summaryElement.innerHTML = this.getSelectionSummaryContent(
                selectedItems,
                totalItems
              )
              this.addSelectionSummaryEventListeners(summaryElement)
            } else {
              summaryElement.remove()
            }
          },

          getOrCreateSummaryElement() {
            let summaryElement =
              gridInstanceRef.instance.element[0].parentNode.querySelector(
                '.selection-summary'
              )
            if (!summaryElement) {
              summaryElement = document.createElement('div')
              summaryElement.className = 'selection-summary'
              gridInstanceRef.instance.element[0].parentNode.insertBefore(
                summaryElement,
                gridInstanceRef.instance.element[0]
              )
            }
            return summaryElement
          },

          getSelectionSummaryContent(selectedItems, totalItems) {
            if (selectedItems === totalItems) {
              return `
                <div class="selection-summary__content">
                  <span>${$translate.instant(
                    'GRID.SELECTION.ALL_ITEMS_SELECTED',
                    {
                      selectedCount: totalItems
                    }
                  )}</span>
                  <a href="#" class="selection-summary__action clear-selection">${$translate.instant(
                    'GRID.SELECTION.CLEAR_SELECTION'
                  )}</a>
                </div>`
            } else {
              return `
                <div class="selection-summary__content">
                  <span>${$translate.instant('GRID.SELECTION.ITEMS_SELECTED', {
                    totalCount: totalItems,
                    selectedCount: selectedItems
                  })}</span>
                  <a href="#" class="selection-summary__action select-all-results">

                  ${$translate.instant('GRID.SELECTION.SELECT_ALL', {
                    totalCount: totalItems
                  })}
               </a>
                </div>`
            }
          },

          addSelectionSummaryEventListeners(summaryElement) {
            const clearSelectionLink =
              summaryElement.querySelector('.clear-selection')
            const selectAllResultsLink = summaryElement.querySelector(
              '.select-all-results'
            )

            if (clearSelectionLink) {
              clearSelectionLink.addEventListener('click', e => {
                e.preventDefault()
                this.clearSelection()
              })
            }

            if (selectAllResultsLink) {
              selectAllResultsLink.addEventListener('click', e => {
                e.preventDefault()
                this.selectAllResults()
              })
            }
          },

          updateUiCheckboxes() {
            const checkboxes =
              gridInstanceRef.instance.element[0].querySelectorAll(
                'input[type="checkbox"][data-selection-id]'
              )
            checkboxes.forEach(checkbox => {
              const dataItem = gridInstanceRef.instance.dataSource.getByUid(
                checkbox.closest('tr').dataset.uid
              )
              checkbox.checked = this.selectedIds.has(
                dataItem[gridInstance.gridOptions.selectionId]
              )
            })
          },

          initializeEventListeners() {
            gridInstanceRef.instance.element[0].removeEventListener(
              'change',
              this.checkboxEventListener
            )
            gridInstanceRef.instance.element[0].addEventListener(
              'change',
              this.checkboxEventListener.bind(this)
            )
          },

          checkboxEventListener(event) {
            if (event.target.type === 'checkbox') {
              if (event.target.dataset.selectAll) {
                event.stopPropagation()
                this.toggleAll(event.target.checked)
              } else {
                const dataItem = gridInstanceRef.instance.dataSource.getByUid(
                  event.target.closest('tr').dataset.uid
                )
                this.toggleItem(dataItem, event.target.checked)
              }
            }
          }
        }

        // Update gridInstanceRef to use the new SelectionManager
        Object.assign(gridInstanceRef, SelectionManager)

        resolve(gridInstanceRef)
      } catch (err) {
        console.error(err)
        reject(err)
      }
    })
  }
  return {
    GridInstance,
    calculateColumnAttributes,
    createComplexTypeMapping
  }
}

module.exports = KendoGridHelper
