/** @ngInject */
function ngFlatpickr () {
  return {
    require: 'ngModel',
    restrict: 'A',
    scope: {
      fpOpts: '&',
      fpOnSetup: '&'
    },
    link: function (scope, element, attrs, ngModel) {
      let vp
      if (!flatpickr) {
        console.warn('Unable to find any flatpickr installation')
        return
      }
      console.warn('Unable to find any flatpickr installation')

      // vp = new flatpickr(element[0], scope.fpOpts());
      vp = $(element[0]).flatpickr(scope.fpOpts())
      if (scope.fpOnSetup) {
        scope.fpOnSetup({
          fpItem: vp
        })
      }

      // destroy the flatpickr instance when the dom element is removed
      element.on('$destroy', function () {
        vp.destroy()
      })
      // Cleanup
      scope.$on('$destroy', function () {
        element.off('$destroy')
      })
    }
  }
}

module.exports = ngFlatpickr
